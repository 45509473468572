import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv'
import * as moment from 'moment'
import { Router } from '@angular/router';
declare let $:any
declare let Swal:any
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.sass']
})
export class CommentsComponent implements OnInit {
    item: any = {
        name: '',
        video_rrt: '',
        video_trt: ''
    }
    loading: any = false;
    showColumn: any = false;
    showEdit: any = false;
    items: any = []
    addCols: any = []
    image: any = {
        title: '',
        src: ''
    }

    column: any  = {
        name: ''
    }

    dynamicCols: any = []

    Years: any = [
        {
            id: 2019,
            name: 2019
        },
        {
            id: 2020,
            name: 2020
        },
        {
            id: 2021,
            name: 2021
        },
        {
            id: 2022,
            name: 2022
        },
        {
            id: 2023,
            name: 2023
        },
        {
            id: 2024,
            name: 2024
        },
        {
            id: 2025,
            name: 2025
        }
    ]

    Months: any = [
        {
            id: '01',
            name: 'January'
        },
        {
            id: '02',
            name: 'February'
        },
        {
            id: '03',
            name: 'March'
        },
        {
            id: '04',
            name: 'April'
        },
        {
            id: '05',
            name: 'May'
        },
        {
            id: '06',
            name: 'June'
        },
        {
            id: '07',
            name: 'July'
        },
        {
            id: '08',
            name: 'August'
        },
        {
            id: '09',
            name: 'September'
        },
        {
            id: '10',
            name: 'October'
        },
        {
            id: '11',
            name: 'November'
        },
        {
            id: '12',
            name: 'December'
        }
    ]

    days: any = [
        {
            id: null,
            name: 'All'
        }
    ]

    searchData: any = {
        year: moment().format('YYYY'),
        month: moment().format("MM"),
        day: moment().format("DD"),
    }
    constructor(public rest: RestService, public router: Router) { }

    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    showMessages(id){
        this.rest.conversation = id;
        this.router.navigate(['/messages'])
    }

    ngOnInit() {
       for(let i = 1; i < 32; i++){
           this.days.push({
               id: this.pad(i, 2, '0'),
               name: this.pad(i, 2, '0'),
           })
       }
       this.search()
    }

    searchComments(){
        this.search()
    }

    getImage(item, num, id){
        this.image.src = ''
        this.loading = true;
        this.rest.getImageData({
            id: id,
            num: num
        }).subscribe((data: any) => {
            this.image.title = 'Preview'
            this.image.src = data.image
            this.loading = false;
        })
    }

    addColumn(){
        if(this.column.name == ''){
            return false;
        }
        this.loading = true;
        this.showColumn = false;
        this.rest.addColumn(this.column).subscribe((data: any) => {
            this.search()
        })
    }
    
    saveComment(){
        this.loading = true;
        this.showEdit = false;
        this.rest.saveComment({cols: this.addCols}).subscribe((data: any) => {
            this.search()
        })
    }

    editComment(item){
        this.addCols = []
        this.dynamicCols.map(d => {
            this.addCols.push({
                comment_col_id: d.id,
                comment_id: item.id,
                name: d.name,
                value: item[d.name]
            })
        })
        this.showEdit = true;
    }

    async deleteColumn(id){
        const res = await Swal.fire({
            title: 'Delete column',
            text: 'Do you want to continue',
            type: 'warning',
            confirmButtonText: 'Ok'
        })
        if(res.value){
            this.loading = true;
            this.showColumn = false;
            this.rest.deleteColumn(id).subscribe((data: any) => {
                this.search()
            })
        }
    }

    export(){
        let json = this.items.map(y => {
            let x =  {
                DealerName: y.DealerName,
                Name: y.name,
                Email: y.email,
                DlrCode: y.DlrCode,
                yearmodel: y.yearmodel,
                project: y.project,
                connectorcolor: y.connectorcolor,
                numberofpoles: y.numberofpoles,
                malefemale: y.malefemale,
                outinsidecar: y.outinsidecar,
                comment: y.comment,
                vc: y.vc,
                system: y.system,
                date: moment(y.created_at).format('LL')
            }
            
            this.dynamicCols.map(d => {
                x[d.name] = y[d.name]
            })
            
            return x
        })
        console.log(json)
        let headers = [
            "Dealer's name",
            "Name",
            "Email",
            "Dealer's code",
            "Year model",
            "Project",
            "Connector Color",
            "Number of poles",
            "Type",
            "Out/Inside",
            "Comment",
            "Vehicle component",
            "System",
            "Date"
        ]
        this.dynamicCols.map(d => {
            headers.push(d.name)
        })
        new Angular5Csv(json, 'Comments', {headers: headers})
    }

    search(){
        this.loading = true;
        this.items = []
        $('table').dataTable().fnDestroy();
        this.rest.getComments(this.searchData).subscribe((data: any) => {
            this.items = data.data;
            this.dynamicCols = data.cols
            
            setTimeout(() => {
                $('table').DataTable({
                    "searching": true
                });
                this.loading = false
            }, 1000)
        })
    }

    download(){
        var img = document.createElement('img');
        img.src = this.image.src;
        var a = document.createElement('a');
        a.setAttribute("download", "preview-"+ new Date() +".jpeg");
        a.setAttribute("href", this.image.src);
        a.appendChild(img);
        document.body.appendChild(a);
        a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
    }

}
