import { Component } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { RestService } from './services/rest.service';
import { Router, NavigationEnd } from '@angular/router';
declare let $:any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    auth: any;

    constructor(public rest: RestService, public router: Router){
        if(!this.isTokenExpired()){
            const decoded: any = jwt_decode(this.getToken());
            this.rest.getAuth(decoded.uid).subscribe((auth: any) => {
                localStorage.setItem('auth_token', auth.token)
                this.auth = jwt_decode(auth.token)
                console.log(this.auth)
                if(this.auth.email == 'chat@hrknissan.ddns.net'){
                    window.location.href = "https://hrknissan.ddns.net/messenger"
                }
            })
        }
        this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd){
                console.log(val)
                if(val.url == '/messages'){
                    $('.dashboard-main-wrapper').css({
                        'overflow': 'hidden !important',
                        'height': '100vh !important',
                    })
                }else{
                    $('.dashboard-main-wrapper').css({
                        'overflow': 'auto',
                        'height': '100vh',
                    })
                }
            }
        })
    }

    getToken(): string {
        return localStorage.getItem('token');
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);
    
        if (decoded.exp === undefined) return null;
    
        const date = new Date(0); 
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(token?: string): boolean {
        if(!token) token = this.getToken();
        if(!token) return true;
    
        const date = this.getTokenExpirationDate(token);
        if(date === undefined) return false;
        return !(date.valueOf() > new Date().valueOf());
    }

    logout(){
        localStorage.removeItem('token')
        window.location.reload()
    }
}
