import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
declare let $: any;
@Component({
  selector: 'app-boschpartno',
  templateUrl: './boschpartno.component.html',
  styleUrls: ['./boschpartno.component.sass']
})
export class BoschpartnoComponent implements OnInit {

    bosch: any = {
        name: '',
        altername: ''
    }
    loading: any = false;
    boschs: any = []

    constructor(public rest: RestService) { }

    ngOnInit() {
        this.search()
    }

    search(){
        this.loading = true;
        this.rest.getElement('BoschPartNo').subscribe(data => {
            $('table').dataTable().fnDestroy();
            this.boschs = data;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": true,
                    "columnDefs": [
                        { "orderable": false, "targets": [1] }
                    ]
                });
                this.loading = false
            }, 1000)
        })
    }

    saveBosch(){
        this.rest.saveElement(this.bosch, 'BoschPartNo').subscribe(ata => {
            this.bosch = {
                name: '',
                altername: ''
            }
            this.search()
        })
    }

    edit(item){
        console.log(item)
        this.bosch = item;
        $('html, body').animate({ scrollTop: $('#form').offset().top}, 1000);
    }

    remove(item){
        this.rest.removeElement(item.id, 'BoschPartNo').subscribe(data => {
            this.search()
        })
    }

}
