import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { AddItemComponent } from './pages/add-item/add-item.component';
import { ConnectorsComponent } from './pages/connectors/connectors.component';
import { ConnectorcolorsComponent } from './pages/connectorcolors/connectorcolors.component';
import { BoschpartnoComponent } from './pages/boschpartno/boschpartno.component';
import { SystemsComponent } from './pages/systems/systems.component';
import { TerminaltypeComponent } from './pages/terminaltype/terminaltype.component';
import { ModelyearComponent } from './pages/modelyear/modelyear.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { NumbersComponent } from './pages/numbers/numbers.component';
import { PolesnumberComponent } from './pages/polesnumber/polesnumber.component';
import { RetainertoolsComponent } from './pages/retainertools/retainertools.component';
import { TerminaltoolsComponent } from './pages/terminaltools/terminaltools.component';
import { StgComponent } from './pages/stg/stg.component';
import { NissantraylocationsComponent } from './pages/nissantraylocations/nissantraylocations.component';
import { InfinitytraylocationsComponent } from './pages/infinitytraylocations/infinitytraylocations.component';
import { HarnessComponent } from './pages/harness/harness.component';
import { 
    AuthGuardService as AuthGuard 
  } from './services/auth-guard.service';
import { MediaComponent } from './pages/media/media.component';
import { ItemComponent } from './pages/item/item.component';
import { TermsComponent } from './pages/terms/terms.component';
import { SessionsComponent } from './pages/sessions/sessions.component';
import { DealersComponent } from './pages/dealers/dealers.component';
import { CommentsComponent } from './pages/comments/comments.component';
import { Ctw1Component } from './pages/ctw1/ctw1.component';
import { Ctw2Component } from './pages/ctw2/ctw2.component';
import { MessagesComponent } from './pages/messages/messages.component';
const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'terms',
        component: TermsComponent
    },
    {
        path: '',
        component: MessagesComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'inventory-data',
        component: InventoryComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/connectors',
        component: ConnectorsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'data/comments',
        component: CommentsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'data/sessions',
        component: SessionsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'data/dealers',
        component: DealersComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'item/:id',
        component: ItemComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/media',
        component: MediaComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/connectorcolors',
        component: ConnectorcolorsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/boschpartno',
        component: BoschpartnoComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/systems',
        component: SystemsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/terminaltype',
        component: TerminaltypeComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/modelyear',
        component: ModelyearComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/projects',
        component: ProjectsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/numbers',
        component: NumbersComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/polenumbers',
        component: PolesnumberComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/retainertools',
        component: RetainertoolsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/terminaltools',
        component: TerminaltoolsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/stg',
        component: StgComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/nissantraylocations',
        component: NissantraylocationsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/infinitytraylocations',
        component: InfinitytraylocationsComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/harness',
        component: HarnessComponent,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/ctw1',
        component: Ctw1Component,
        canActivate: [AuthGuard] 
    },
    {
        path: 'elements/ctw2',
        component: Ctw2Component,
        canActivate: [AuthGuard] 
    },
    {
        path: 'messages',
        component: MessagesComponent,
        canActivate: [AuthGuard] 
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
