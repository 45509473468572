import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import * as moment from 'moment'
declare let $:any
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.sass']
})
export class MessagesComponent implements OnInit {

    conversations: any = [
        {
            name: 'test',
            dealer: 'Dealer',
            dealerCode: '123456',
            email: 'test@test',
            lastDate: '10/11/2019',
            messages: [
                {
                    from: 'user',
                    message: 'Hola'
                }
            ]
        }
    ]
    conversationsAll: any = [
        {
            name: 'test',
            dealer: 'Dealer',
            dealerCode: '123456',
            email: 'test@test',
            lastDate: '10/11/2019',
            messages: [
                {
                    from: 'user',
                    message: 'Hola'
                }
            ]
        }
    ]
    conversation: any = {
        name: '',
        messages: []
    };
    date: any;
    date2: any;
    search: string = ''
    messageText: string = ''
    loading: boolean = false;
    image: any = {
        title: '',
        src: ''
    }


    constructor(public rest: RestService) { }

    selectConversation(conversation){
        this.conversations = this.conversations.map(c => {
            c.active = false
            return c
        })
        conversation.active = true;
        this.conversation = conversation
        this.rest.setSeen(this.conversation.id, 'id').subscribe((data) => {
            this.conversation.unread = 0
            this.conversation.messages = this.conversation.messages.map(x => {
                x.seen = 1;
                return x
            })
        })
        setTimeout(() => {
            $('.messages-chat').animate({ scrollTop: $('.messages-chat').prop("scrollHeight")}, 1000);
        }, 1000)
    }

    getImage(item, num, id){
        this.image.src = ''
        this.loading = true;
        this.rest.getImageData({
            id: id,
            num: num
        }).subscribe((data: any) => {
            this.image.title = 'Preview'
            this.image.src = data.image
            this.loading = false;
        })
    }
    
    getImageMessage(message){
        this.image.src = ''
        this.loading = true;
        this.rest.getImageData({
            id: message.id,
            message: true
        }).subscribe((data: any) => {
            this.image.title = 'Preview'
            this.image.src = data.image
            this.loading = false;
        })
    }

    ngOnInit() {
        var date = new Date();
        this.date2 = moment().add(1, 'days').format('DD/MM/YYYY')
        this.date = moment().format('DD/MM/YYYY')
        this.searchData('?start='+ moment().format('YYYY-MM-DD') +'&end='+ moment().add(1, 'days').format('YYYY-MM-DD'))
    }

    onDate(){
        this.searchData('?start='+ moment(this.date).format('YYYY-MM-DD') +'&end='+ moment(this.date2).format('YYYY-MM-DD'))
        // if(!this.date){
        //     this.conversations = this.conversationsAll
        // }else{
        //     this.conversations = this.conversationsAll.filter(x => x.lastDate == moment(this.date).format('DD/MM/YYYY'))
        // }
    }

    searchData(filters){
        this.loading = true;
        this.rest.getConversations(filters).subscribe((data: any) => {
            this.conversationsAll = data.map(item => {
                item.lastDate = (item.messages.length > 0) ? moment(item.messages[item.messages.length - 1].created_at).format('DD/MM/YYYY') : moment(item.created_at).format('DD/MM/YYYY')
                item.lastDateF = (item.messages.length > 0) ? item.messages[item.messages.length - 1].created_at : item.created_at
                item.unread = item.messages.filter(x => x.seen == 0 && x.from == 'user').length
                return item
            }).sort((a:any,b:any) => {
                var dateA = new Date(a.lastDateF).getTime();
                var dateB = new Date(b.lastDateF).getTime();
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
            });
            this.conversations = this.conversationsAll;
            if(data.length > 0){
                this.conversation = this.conversations[0]
                this.conversation.active = true;
            }
            if(this.rest.conversation){
                let conversation = this.conversations.find(x => x.id == this.rest.conversation)
                if(conversation){
                    this.selectConversation(conversation)
                    this.rest.conversation = null;
                }
            }
            
            this.loading = false
        })
    }

    sendMessage(){
        if(this.messageText !== ''){
            this.rest.sendMessage({
                comment_id: this.conversation.id,
                message: this.messageText,
                from: 'company'
            }).subscribe(() => {
                this.messageText = ''
                this.searchData('?start='+ moment(this.date).format('YYYY-MM-DD') +'&end='+ moment(this.date2).format('YYYY-MM-DD'))
            })
        }
    }

}
