import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class RestService {

    //api: any = 'http://localhost:3333/'
    //api: any = 'http://178.128.10.171/'
    api: any = 'https://hrknissan.ddns.net/'
    //api: any = 'http://167.99.98.129/'
    bearer: any = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    }
    conversation: any;

    constructor(public http: HttpClient) { }

    login(credentials){
        return this.http.post(this.api + 'auth/login', credentials)
    }

    getAuth(uid){
        return this.http.get(this.api + 'auth?uid=' + uid, this.bearer)
    }

    clean(obj) {
        Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);
        return obj
    }

    parseParams(params?){
        if(params){
            return Object.keys(params).map(function(k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
            }).join('&')
        }
        return ''
    }

    getSessions(data){
        let params = this.parseParams(data)
        return this.http.get(this.api + 'sessions?' + params, this.bearer)
    }
    
    getAnalytics(data){
        let params = this.parseParams(data)
        return this.http.get(this.api + 'history?' + params, this.bearer)
    }
    
    getImageData(data){
        let params = this.parseParams(data)
        return this.http.get(this.api + 'comments-image?' + params, this.bearer)
    }
    
    getComments(data){
        let params = this.parseParams(data)
        return this.http.get(this.api + 'comments?' + params, this.bearer)
    }
    
    getDealers(){
        return this.http.get(this.api + 'dealers', this.bearer)
    }

    getElement(type){
        return this.http.get(this.api + 'elements?type=' + type, this.bearer)
    }

    getImage(name){
        return this.http.get(this.api + 'image?name=' + name, this.bearer)
    }

    getImages(bosch){
        return this.http.get(this.api + 'images-by-bosch?bosch=' + bosch, this.bearer)
    }
    
    getConversations(filters){
        return this.http.get(this.api + 'conversations' + filters, this.bearer)
    }
    
    getAll(){
        return this.http.get(this.api + 'all', this.bearer)
    }

    getInventory(params?){
        return this.http.get(this.api + 'inventory?' + this.parseParams(this.clean(params)), this.bearer)
    }

    getItem(id){
        return this.http.get(this.api + 'get-item?id=' + id, this.bearer)
    }

    deleteItem(id){
        return this.http.delete(this.api + 'delete-item?id=' + id, this.bearer)
    }
    
    deleteColumn(id){
        return this.http.delete(this.api + 'delete-column?id=' + id, this.bearer)
    }

    saveColor(color){
        return this.http.post(this.api + 'save-color', color, this.bearer)
    }
    
    sendMessage(message){
        return this.http.post(this.api + 'save-message', message, this.bearer)
    }

    saveIventory(item){
        return this.http.post(this.api + 'save-inventory', item, this.bearer)
    }
    
    saveComment(items){
        return this.http.post(this.api + 'save-comment', items, this.bearer)
    }
    
    addColumn(item){
        return this.http.post(this.api + 'add-column', item, this.bearer)
    }
    
    saveElement(item, type?){
        if(type){
            item.type = type
        }
        return this.http.post(this.api + 'elements', item, this.bearer)
    }

    cutImage(item){
        return this.http.post(this.api + 'cut-image', item, this.bearer)
    }
    
    saveImage(item){
        return this.http.post(this.api + 'save-image', item, this.bearer)
    }
    
    setSeen(id, type = 'sessionid'){
        return this.http.get(this.api + 'seen?'+ type +'=' + id)
    }

    removeImage(id){
        return this.http.delete(this.api + 'delete-image?id=' + id, this.bearer)
    }
    
    removeColor(color){
        return this.http.delete(this.api + 'remove-color?id=' + color, this.bearer)
    }
    
    removeElement(item, type){
        return this.http.delete(this.api + 'remove-element?id=' + item + '&type=' + type, this.bearer)
    }
}
