import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';

declare let $:any;
@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.sass']
})
export class MediaComponent implements OnInit {

    BoschPartNumbersFull: any = []
    BoschPartNumbers: any = []
    PageNumbers: any = [
        {
            id: 0,
            name: 'Custom image'
        }
    ]
    editImageOnly: any;
    customImage: boolean = true;
    search: any = {
        BoschPartNo: '0',
        PageNumber: 0
    }
    showEditor: boolean = false;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    croppedAreaApplied: boolean = false;
    height: any = 0;
    width: any = 0;
    images: any = []

    constructor(public rest: RestService) {
        for(let i = 1; i < 246; i++){
            this.PageNumbers.push({
                id: i,
                name: i
            })
        }
    }
    
    fileChangeEvent(event: any, image?): void {
        var FR= new FileReader();
        let self = this;
    
        FR.addEventListener("load", function(e: any) {
            self.imageChangedEvent = e.target.result
        }); 
        if(image){
            FR.readAsDataURL( $('#image-' + image)[0].files[0] );
            //FR.readAsDataURL( $('#image')[0].files[0] );
        }else{
            FR.readAsDataURL( $('#image')[0].files[0] );
        }
        this.showEditor = true;
    }
    
    fileChangeEvent2(event: any, image?): void {
        var FR= new FileReader();
        let self = this;
    
        FR.addEventListener("load", function(e: any) {
            let b64 = 'data:image/png;base64,' + e.target.result.split(',')[1]
            self.imageChangedEvent = b64
            self.croppedImage = b64;
        }); 
        FR.readAsDataURL( $('#image2')[0].files[0] );
        this.showEditor = true;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.height = event.height
        this.width = event.width
    }
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

    pad(el,n) {
        if(el < 100){
            return new Array(n).join('0').slice((n || 2) * -1) + el;
        }else{
            return el;
        }
    }

    saveImage(){
        const formData = new FormData();
        if(this.editImageOnly.id){
            formData.append('id', this.editImageOnly.id)
        }
        formData.append('image', this.croppedImage)
        formData.append('name', this.editImageOnly.name)
        formData.append('meta', this.editImageOnly.meta)
        formData.append('url', this.editImageOnly.url)
        formData.append('boschpartno', this.editImageOnly.boschpartno)
        this.rest.saveImage(formData).subscribe((images: any) => {
            images.images =  images.images.map(image => {
                image.url = this.rest.api + '/' + image.url
                image.time = new Date().getTime()
                return image
            })
            images.images = this.addImages(images.images)
            this.images = images.images
            this.showEditor = false;
            this.croppedAreaApplied = false;
            this.croppedImage = ''
        })
    }

    editImage(image){
        this.editImageOnly = image;
        let url = image.url.replace(this.rest.api + '/','')
        this.rest.getImage(url).subscribe((image: any) => {
            this.imageChangedEvent = image.base;
            this.showEditor = true;
            $('html, body').animate({ scrollTop: $('#editor').offset().top}, 1000);
        })
    }

    cancel(){
        this.showEditor = false
        this.croppedAreaApplied = false
        this.imageChangedEvent = ''
    }

    removeImage(image){
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this image!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                this.rest.removeImage(image.id).subscribe((images: any) => {
                    images.images = images.images.map((image: any) => {
                        image.url = this.rest.api + '/' + image.url
                        image.time = new Date().getTime()
                        return image
                    })
                    images.images = this.addImages(images.images)
                    this.images = images.images
                })
            }
        })
    }

    changeTypeImage(e, image?){
        if(image){
            if(image.PageNumber > 0){
                image.customImage = false;
            }else{
                image.customImage = true;
            }
        }else{
            if(this.search.PageNumber > 0){
                this.customImage = false;
            }else{
                this.customImage = true;
            }
        }
    }

    applyCroppedArea(bol){
        this.croppedAreaApplied = bol;
    }

    selectImage(){
        $('#image').trigger('click')
        
    }
    selectImage2(){
        $('#image2').trigger('click')
        
    }

    ngOnInit() {
        this.rest.getElement('BoschPartNo').subscribe((data: any) => {
            this.BoschPartNumbersFull = data;
            data = data.filter(x => x.id !==  1)
            data.sort(function(a, b){
                if(Number(a.name.replace('J-48817-','')) < Number(b.name.replace('J-48817-',''))) { return -1; }
                if(Number(a.name.replace('J-48817-','')) > Number(b.name.replace('J-48817-',''))) { return 1; }
                return 0;
            })
            this.BoschPartNumbers = data;
        })
    }

    getImages(event){
        this.editImageOnly = null;
        this.images = []
        setTimeout(() => {
            this.rest.getImages(this.search.BoschPartNo).subscribe((images:any) => {
                console.log(images)
                if(images.length > 0){
                    images =  images.map(image => {
                        image.customImage = false;
                        image.url = this.rest.api + '/' + image.url
                        image.time = new Date().getTime()
                        return image
                    })
                    images = this.addImages(images)
                    this.images = images
                }else{
                    this.generateImages()
                }
            })
        }, 100)
    }


    async searchImage(image?){
        if(image){
            this.editImageOnly = image
            this.rest.getImage('J-48817_HRK/J-48817_HRK_page-0'+ this.pad(image.PageNumber,2) +'.jpg').subscribe((image: any) => {
                this.imageChangedEvent = image.base;
                this.showEditor = true;
                this.croppedAreaApplied = false;
                $('html, body').animate({ scrollTop: $('#editor').offset().top}, 1000);
            })
        }else{
            this.images = []
            this.rest.getImage('J-48817_HRK/J-48817_HRK_page-0'+ this.pad(this.search.PageNumber,2) +'.jpg').subscribe((image: any) => {
                this.imageChangedEvent = image.base;
                this.showEditor = true;
                this.croppedAreaApplied = false;
                $('html, body').animate({ scrollTop: $('#editor').offset().top}, 1000);
            })
        }
    }

    generateImages(){
        const formData = new FormData();
        formData.append('BoschPartNo', this.search.BoschPartNo)
        formData.append('image', '')
        formData.append('width', this.width)
        formData.append('height', this.height)
        this.rest.cutImage(formData).subscribe((images: any) => {
            if(images.images && images.images.length > 0){
                images.images =  images.images.map(image => {
                    image.url = this.rest.api + '/' + image.url
                    image.time = new Date().getTime()
                    return image
                })
                this.showEditor = false;
                this.croppedAreaApplied = false;
                images.images = this.addImages(images.images)
                this.images = images.images
            }else{
                let images = [
                    {
                        name: 'retainer',
                        boschpartno: this.search.BoschPartNo,
                        added: false,
                        PageNumber: 0,
                        meta: null,
                        customImage: false
                    },
                    {
                        name: 'retainer',
                        boschpartno: this.search.BoschPartNo,
                        added: false,
                        PageNumber: 0,
                        meta: null,
                        customImage: false
                    },
                    {
                        name: 'terminal',
                        boschpartno: this.search.BoschPartNo,
                        added: false,
                        PageNumber: 0,
                        meta: null,
                        customImage: false
                    },
                    {
                        name: 'terminal',
                        boschpartno: this.search.BoschPartNo,
                        added: false,
                        PageNumber: 0,
                        meta: null,
                        customImage: false
                    },
                    {
                        name: 'stg',
                        boschpartno: this.search.BoschPartNo,
                        added: false,
                        PageNumber: 0,
                        meta: 'RETAINER REMOVAL TOOL',
                        customImage: false
                    },
                    {
                        name: 'stg',
                        boschpartno: this.search.BoschPartNo,
                        added: false,
                        PageNumber: 0,
                        meta: 'SOLDER SLEEVE',
                        customImage: false
                    },
                    {
                        name: 'stg',
                        boschpartno: this.search.BoschPartNo,
                        added: false,
                        PageNumber: 0,
                        meta: 'SPREAD TERMINAL GAUGE',
                        customImage: false,
                    },
                ]
                this.showEditor = false;
                this.croppedAreaApplied = false;
                this.images = images

            }
        })
    }

    addImages(images){

        if(images){
            let bosch = this.BoschPartNumbers.filter(x => x.id == this.search.BoschPartNo)[0]
            let findRetainer = images.filter(x => x.name == 'retainer')
            if(findRetainer.length == 0){
                images.push({
                    name: 'retainer',
                    url: 'connectors/retainer-01_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: null,
                    customImage: false
                })
                images.push({
                    name: 'retainer',
                    url: 'connectors/retainer-02_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: null,
                    customImage: false
                })
            }
            if(findRetainer.length == 1){
                images.push({
                    name: 'retainer',
                    url: 'connectors/retainer-02_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: null,
                    customImage: false
                })
            }
            let findTerminal = images.filter(x => x.name == 'terminal')
            if(findTerminal.length == 0){
                images.push({
                    name: 'terminal',
                    url: 'connectors/terminal-01_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: null,
                    customImage: false
                })
                images.push({
                    name: 'terminal',
                    url: 'connectors/terminal-02_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: null,
                    customImage: false
                })
            }
            if(findTerminal.length == 1){
                images.push({
                    name: 'terminal',
                    url: 'connectors/terminal-02_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: null,
                    customImage: false
                })
            }
            let findSTG1 = images.filter(x => x.meta == 'RETAINER REMOVAL TOOL')
            if(findSTG1.length == 0){
                images.push({
                    name: 'stg',
                    url: 'connectors/rrt_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: 'RETAINER REMOVAL TOOL',
                    customImage: false
                })
            }
            let findSTG2 = images.filter(x => x.meta == 'SOLDER SLEEVE')
            if(findSTG2.length == 0){
                images.push({
                    name: 'stg',
                    url: 'connectors/solder-sleeve_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: 'SOLDER SLEEVE',
                    customImage: false
                })
            }
            let findSTG3 = images.filter(x => x.meta == 'SPREAD TERMINAL GAUGE')
            if(findSTG3.length == 0){
                images.push({
                    name: 'stg',
                    url: 'connectors/stg_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: 'SPREAD TERMINAL GAUGE',
                    customImage: false
                })
            }
            let findSTG4 = images.filter(x => x.meta == 'TERMINAL REMOVAL TOOL')
            if(findSTG4.length == 0){
                images.push({
                    name: 'stg',
                    url: 'connectors/trt_'+ bosch.name +'.jpg',
                    boschpartno: bosch.name,
                    added: false,
                    PageNumber: 0,
                    meta: 'TERMINAL REMOVAL TOOL',
                    customImage: false
                })
            }
        }
        return images;
    }
}
