import { Component, OnInit, ChangeDetectorRef, NgZone} from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

declare let $: any;

@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.sass']
})
export class InventoryComponent implements OnInit {

    search: any = {
        OutInsid: null,
        ConnectorcolorId: null,
        NumberOfPolesId: null,
        MaleFemaleId: null,
        connector: '',
        web: true
    }
    element: any = {
        id: null,
        YearModelId:0, 
        ProjectId:0, 
        HarnessId:0, 
        NumberId:0, 
        SystemId:0, 
        ConnectorId:0, 
        NoteId:0, 
        OutInsideCarId:0, 
        Group2Id:0, 
        Group3Id:0, 
        Group4Id:0, 
        BoschPartNoId:0, 
        ConnectorColorId:0, 
        NumberOfPolesId:0, 
        MaleFemale:0, 
        CTW1NoId:0, 
        CTW2NoId:0, 
        ToolRetainerId:0, 
        ToolTerminalId:0, 
        STGId:0, 
        NissanTrayLocationsId:0, 
        InfinityTrayLocationsId:0, 
        TypeTerminalId:0, 
        Extras2Id:0, 
        NissanTrayLocationsTerminalId:0, 
        InfinitiTrayLocationsTerminalId:0
    }
    config: any = {
        displayKey:"name", //if objects array passed which key to be displayed defaults to description
        search:true, //true/false for the search functionlity defaults to false,
        height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
        placeholder:'Select' ,// text to be displayed when no item is selected defaults to Select,
        moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
        noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
        searchPlaceholder:'Search', // label thats displayed in search input,
        searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
        bindKey: 'id'
    }
    loading: boolean = false;
    inventory: any = []
    ConnectorColor: any = []
    Numberofpole: any = []
    Project: any = []
    YearModel: any = []
    Harness: any = []
    Numbers: any = []
    System: any = []
    Connector: any = []
    Note: any = []
    Outinsidecar: any = []
    Group2: any = []
    Group3: any = []
    Group4: any = []
    BoschPartNo: any = []
    MaleFemale: any = []
    CTW1No: any = []
    CTW2NO: any = []
    Retainertool: any = []
    Terminaltool: any = []
    STG: any = []
    Nissantraylocation: any = []
    Infinitytraylocation: any = []
    TypeTerminal: any = []
    Extra2: any = []
    NissanTrayLocationsTerminal: any = []
    InfinitiTrayLocationsTerminal: any = []

    constructor(public rest: RestService, private chRef: ChangeDetectorRef, private zone: NgZone, public router: Router) { }

    async ngOnInit() {
        $.fn.dataTable.ext.errMode = 'none';
        this.loading = true;

        this.rest.getAll().subscribe((data: any) => {
            this.Project = data.Project
            this.YearModel = data.YearModel
            this.Harness = data.Harness
            this.Numbers = data.Numbers
            this.System = data.System
            this.Connector = data.Connector
            this.Note = data.Note
            this.Outinsidecar = data.Outinsidecar
            this.Group2 = data.Group2
            this.Group3 = data.Group3
            this.Group4 = data.Group4
            this.BoschPartNo = data.BoschPartNo
            this.ConnectorColor = data.ConnectorColor
            this.Numberofpole = data.Numberofpole
            this.MaleFemale = data.MaleFemale
            this.CTW1No = data.CTW1No
            this.CTW2NO = data.CTW2NO
            this.Retainertool = data.Retainertool
            this.Terminaltool = data.Terminaltool
            this.STG = data.STG
            this.Nissantraylocation = data.Nissantraylocation.filter(x => x.type == 1)
            this.Infinitytraylocation = data.Infinitytraylocation.filter(x => x.type == 1)
            this.NissanTrayLocationsTerminal = data.Nissantraylocation.filter(x => x.type == 2)
            this.InfinitiTrayLocationsTerminal = data.Infinitytraylocation.filter(x => x.type == 2)
            this.TypeTerminal = data.TypeTerminal
            this.Extra2 = data.Extra2
            this.loading = false;
        })
    }

    edit(item){
        this.router.navigate(['item', item.InventoryId])
    }

    addItem(){
        this.router.navigate(['item', 'new']) 
    }

    remove(item){
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this item file!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this.rest.deleteItem(item.InventoryId).subscribe(() => {
                    
                    this.searchInventory(true)
                })
            // For more information about handling dismissals please visit
            // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'The item is safe',
                'error'
              )
            }
        })
    }

    searchInventory(deleted?){
        this.loading = true;
        this.rest.getInventory(this.search).subscribe((inventory: any) => {
            inventory = inventory.filter(item => {
                if(this.search.PartNo){
                    if(item.PartNo == this.search.PartNo){
                        return true;
                    }
                    else{
                        return false;
                    }
                    
                }

                return true;
            }).map(item => {
                item.ConnectorImage = this.rest.api + item.ConnectorImage
                return item
            })
            $('table').dataTable().fnDestroy();
            this.inventory = inventory;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": false,
                    "columnDefs": [
                        { "orderable": false, "targets": [1,2,3,4,5,6,7] }
                    ]
                });
                if(deleted){
                    Swal.fire(
                        'Deleted!',
                        'The item has been deleted.',
                        'success'
                    )
                }
                this.loading = false
            }, 1000)
        })
    }

}
